@use "../../common/breakpoints" as *;
@use "../../common/mixin" as *;
@use "../../common/color" as *;

.true_and_false{
    .inner__headerdash{
        position: relative;
        justify-content: center!important;
    }

    .total__out__leveldata{
        position: absolute;
        right: 0;
    }
}