@use "../../common/breakpoints" as *;
@use "../../common/mixin" as *;
@use "../../common/color" as *;

/* Quiz play */

.funandlearn {
  display: flex;
  color: $white;

  .morphisam {
    @include glassmorphism-effect;
    padding: 30px 10px;

    &::before {
      position: absolute;
      left: -79px;
      content: "";
      top: -54px;
      width: 300px;
      height: 300px;
      background-color: $white;
      opacity: 0.2;
      border-color: $white;
      border-radius: 50%;
    }
  }

  .quiz__logo {
    @include flex-center;
    margin: auto;
  }

  .left-sec {
    .bottom__left {
      display: block;

      .cat__Box {
        display: flex;
        flex-direction: row;
        position: relative;
        margin-bottom: 40px;

        .left-line {
          border: 1px solid rgb(179, 179, 179);
          margin: auto;
          display: block;
          width: 50px;
          align-items: center;
          justify-content: center;
          line-height: 2px;
        }

        .funandlearn__title {
          margin: auto 10px;
          font-size: 1.25rem;
          @media #{$large-device} {
            font-size: inherit;
          }
        }

        .right-line {
          border: 1px solid rgb(179, 179, 179);
          margin: auto;
          width: 50px;
          @include flex-center;
          line-height: 2px;
        }
      }

      .bottom__cat__box {
        display: flex;

        .inner__Cat__box {
          width: 100%;
          height: 405px;
          overflow-y: auto;
          list-style-type: none;
          text-decoration: none;
          padding-left: 0;
          position: relative;

          @media (max-width: 991px) {
            height: auto;
          }

          @media (max-width: 575px) {
            height: auto;
          }

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: $primarybtn;
          }

          .button {
            display: flex;
            border-radius: 15px;
            align-items: center;
            position: relative;
            text-align: center;
            margin-bottom: 10px;
            // background-color: #5f5f5f;

            &:hover {
              &::after {
                cursor: pointer;
                transition: all 1s ease-in-out;
                width: 100%;
              }
            }

            &::after {
              width: 50px;
              height: 55px;
              color: $white;
              background-color: $primarybtn;
              border-radius: 13px;
              position: absolute;
              left: 0;
              content: "";
              cursor: pointer;
            }

            .active {
              border: 10px;
            }
            .button.active-one {
              &::after {
                height: 100px !important;
              }
            }
          }

          .Box__icon {
            margin-left: 10px;
            @include flex-center;
            z-index: 1;
            color: $white;

            img {
              width: 30px;
              height: 30px;
              max-width: 100%;
              max-height: 100%;
              border-radius: 5px;
            }
          }

          .Box__text {
            margin-left: 20px;
            text-align: center;
            @include flex-center;
            padding-top: 15px;
            @include flex-center;
            z-index: 1;
            color: $white;
          }

          li {
            cursor: pointer;
          }
        }
      }
    }
  }

  // subcatslider
  .subcat__slider__context {
    display: block;
    position: relative;

    .inner__icon {
      @include flex-center;
      margin: auto;
      position: absolute;
      content: "";
      width: 100%;
      height: 2px;
      display: inline-block;
      background-color: grey;
    }

    .cat__Box {
      display: flex;
      flex-direction: row;
      position: relative;

      .left-line {
        border: 1px solid rgb(179, 179, 179);
        margin: auto;
        display: block;
        width: 30%;
        align-items: center;
        justify-content: center;
        line-height: 2px;
      }

      .funandlearn__title {
        margin: auto 10px;
      }

      .right-line {
        border: 1px solid rgb(179, 179, 179);
        margin: auto;
        @include flex-center;
        width: 30%;
        line-height: 2px;
      }

      .subcat__p {
        font-size: 1.25rem;
        display: block;
        margin: 5px auto;
        justify-content: center;
        text-align: center;
        align-items: center;

        @media #{$large-device} {
          font-size: inherit;
        }
      }
    }

    .quizplay-slider {
      padding: 20px 0px;
      position: relative;

      .subcatintro__sec {
        margin: 30px auto;
        .card {
          height: 100px;
          border-radius: 20px;
          background-color: #5f5f5f;
          cursor: pointer;

          .card__name {
            p:first-child {
              font-size: 1rem;
              font-weight: 800;
              padding-bottom: 7px;
            }
            p {
              font-size: 0.825rem;
              font-weight: 500;
              letter-spacing: 1px;
            }
          }
        }
      }

      .no_sub_data {
        position: absolute;
        top: 0%;
        left: 0;
        right: 0;
        @include flex-center;
      }

      .swiper-button-next {
        right: 0px;
        color: $white;
        background-color: grey;
        padding: 0px 23px;
        border-radius: 50%;
        opacity: 1;
        cursor: pointer;

        &:hover {
          background-color: $themecolor !important;
          overflow: hidden;

          &::after {
            animation: moveoutright 1s linear;
          }

          @keyframes moveoutright {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(100px);
            }
          }
        }
      }

      .swiper-button-prev {
        left: 0px;
        color: $white;
        background-color: grey;
        padding: 0px 23px;
        border-radius: 50%;
        opacity: 1;
        cursor: pointer;

        &:hover {
          background-color: $themecolor !important;
          overflow: hidden;

          &::after {
            animation: moveoutleft 1s linear;
          }

          @keyframes moveoutleft {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-100px);
            }
          }
        }
      }

      .swiper-wrapper {
        margin: 0 60px;

        @media #{$large-device} {
          margin: auto;
        }
      }
    }

    .sub_cat_title {
      @include flex-center;
    }

    .active-one {
      color: $themecolor;
      background-color: $mate-black !important;
      border: 2px solid $themecolor;
    }

    .unactive-one {
      color: $mate-black;
    }
  }

  // level

  .right__bottom {
    &.cat__Box {
      display: flex;
      flex-direction: row;
      position: relative;

      h6 {
        font-size: 1.25rem;

        @media #{$large-device} {
          font-size: inherit;
        }
      }
    }

    .left-line,
    .right-line {
      border: 1px solid rgb(179, 179, 179);
      margin: auto;

      width: 30%;
      @include flex-center;
      line-height: 2px;
      display: block;
      align-items: center;
      justify-content: center;
      line-height: 2px;
    }
  }

  .unlock__levels__card {
    position: relative;
    top: 0;
    left: 0;

    .card {
      height: 85px;
      border-radius: 20px;
      background-color: #212121;
      @include flex-center;
      flex-direction: column;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      .bubble-aprox {
        right: 100%;
        bottom: 100%;
        width: 100%;
        height: 100%;
        background-color: $primarybtn;
        transition: all 0.3s;
        border-bottom-right-radius: 10%;
        position: absolute;
      }

      &:hover {
        .bubble-aprox {
          transition: all 0.3s;
          right: 0;
          bottom: 0;
        }
      }

      .questions {
        .inner_que {
          padding-left: 5px;
        }
      }

      .open_lock {
        color: $themecolor;
      }
    }
  }
}

.custom-btn {
  background-color: $themecolor;
  color: #212121;
}

.fun__title {
  color: #fff;
}

.funandlearnplay {
  .inner__headerdash {
    position: relative;
    justify-content: center !important;
  }

  .total__out__leveldata {
    position: absolute;
    right: 0;
  }
}

/* quizzzone */

.Quizzone {
  margin: 100px 0;

  @media (max-width: 767px) {
    margin: 20px 0;
  }

  .inner__Quizzone {
    display: block;
    justify-content: center;
    width: 100%;
    align-items: center;

    @media #{$extra-small-device} {
      margin-top: 50px;
      margin-bottom: 10px;
    }

    .card {
      @include flex-center;
      height: 150px;
      text-align: center;
      border-radius: 20px;
      background-color: #ffffffcc;
      -webkit-box-shadow: 20px 10px 50px -16px $primarybtn;
      -moz-box-shadow: 20px 10px 50px -16px $primarybtn;
      box-shadow: 20px 10px 50px -16px $primarybtn;
      position: relative;
      cursor: pointer;

      .card__icon {
        margin-top: -80px;

        img {
          height: 160px;
        }
      }

      &:hover {
        background: #5f5f5f;
        -webkit-transition: all 0.25s;
        -moz-transition: all 0.25s;
        -ms-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 1.25s;
        transform: scale(0.9);
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(249, 131, 103, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);

        img {
          position: absolute;
          right: -10%;
          top: -40%;
          transition: 0.3s ease-in-out;
          -webkit-transform: rotate(0deg) scale(0.65) translate(0px);
          -moz-transform: rotate(0deg) scale(0.65) translate(0px);
          -ms-transform: rotate(0deg) scale(0.65) translate(0px);
          -o-transform: rotate(0deg) scale(0.65) translate(0px);
          transform: rotate(0deg) scale(0.65) translate(0px);
          -webkit-transform-origin: center right;
          -moz-transform-origin: center right;
          -ms-transform-origin: center right;
          transform-origin: center right;
          opacity: 1;
        }

        .inner__title {
          margin-top: 95px;
          transition: 0.4s ease-in-out;
          opacity: 1;
          font-size: 1.3rem !important;
        }
      }

      .title__card {
        display: block;
        padding: 10px 10px;
        margin-top: -25px;

        .inner__title {
          color: $themecolor;
          text-transform: capitalize;
          letter-spacing: 1px;
          font-size: 1rem;
          font-weight: 900;
        }
      }
    }

    .card_disabled {
      @include flex-center;
      height: 150px;
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0 8px 32px 0 rgba(249, 131, 103, 0.37);
      backdrop-filter: blur(2px);
      text-align: center;
      border-radius: 20px;
      -webkit-box-shadow: 20px 10px 50px -16px $primarybtn;
      -moz-box-shadow: 20px 10px 50px -16px $primarybtn;
      box-shadow: 20px 10px 50px -16px $primarybtn;
      position: relative;
      cursor: pointer;
      flex-direction: column;

      .card__icon {
        margin-top: -80px;

        img {
          height: 160px;
        }
      }

      .title__card {
        display: block;
        padding: 10px 10px;
        margin-top: -25px;

        .inner__title {
          color: $themecolor;
          text-transform: capitalize;
          letter-spacing: 1px;
          font-size: 1rem;
          font-weight: 900;
        }
      }
    }
  }

  ul {
    padding-left: 0;

    li {
      text-decoration: none;
      list-style: none;
      margin: 50px 0;
      @media #{$extra-small-device} {
        margin: 0;
      }
      &:nth-child(1) {
        margin-left: 150px;
        @media #{$extra-small-device} {
          margin: 0;
        }

        @media (min-width: 480px) and (max-width: 1199px) {
          margin: 50px 0;
        }
      }
      &:nth-child(4) {
        margin-right: 150px;
        @media #{$max-width-1199-device} {
          margin: auto;
        }
      }

      @media #{$very-small-device} {
        width: 100%;
        margin: auto;
      }
    }
  }

  .small__div {
    @media #{$desktop-device} {
      width: 20%;
    }
  }
}

.custom-icon {
  font-size: 1.5em;
}
.custom-icon strong {
  margin-top: 0.7rem;
  font-size: 0.7em;
}

@media (min-width: 1400px) {
  .custom-col-xxl {
    flex: 0 0 auto;
    width: 13%;
  }
}

.custom_row {
  margin-left: 55px;

  @media #{$custom-max-1399-device} {
    margin-left: 0;
  }
}
