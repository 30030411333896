@use "../common/breakpoints" as *;
@use "../common/mixin" as *;
@use "../common/color" as *;

.Bookmark {
  padding-bottom: 50px;

  @media #{$max-wid-device} {
    margin-top: 0;
  }

  .Breadcrumb {
    @media #{$max-wid-device} {
      margin-top: 150px;
    }
  }
  .morphisam {
    @include glassmorphism-effect;
    padding: 30px;
    position: relative;




    &::before {
      position: absolute;
      left: -120px;
      content: "";
      top: -150px;
      width: 300px;
      height: 300px;
      background-color: $white;
      opacity: 0.2;
      border-color: $white;
      border-radius: 50%;
    }

    @media #{$max-wid-device} {
      padding: 50px 15px;
    }

    @media #{$small-device} {
      padding: 15px;
    }

    .flex-column {
      border: 1px solid #eeeeee2b;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
    }

    .tab-content {
      @extend .flex-column;
    }

    .nav-item {
      margin-bottom: 15px;
      cursor: pointer;
    }

    .nav-pills {
      .nav-link {
        color: $white;
        text-align: center;
        &.active {
          background-color: $themecolor;
          color: $black;
          @include normal-transition;
        }
      }
      .show {
        > .nav-link {
          background-color: $themecolor;
          @include normal-transition;
        }
      }
    }

    .outer__stage {
      color: $white;
      margin-bottom: 30px;
      @media #{$small-device} {
        margin-bottom: 15px;
      }

      .three__stage {
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media #{$small-device} {
          flex-direction: column;
          text-align: center;
          justify-content: center;
        }


        .number_stage {
          span {
            background-color: $themecolor;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            @include flex-center;
            font-size: 20px;
            font-weight: 600;
          }
        }

        .content_stage {
          width: 800px;

          @media #{$desktop-device} {
            width: 600px;
          }

          @media #{$small-device} {
            width: auto;
            margin: 15px auto;
          }
          p {
            color: $white;
            font-weight: 700;
            font-size: 1.1em;
            @media #{$max-wid-device} {

              font-size: 14px;
            }
          }
          span {
            color: $white;
            @media #{$max-wid-device} {
              margin: 0 15px;
              font-size: 14px;
            }

            span{
              font-weight: 700;
              text-decoration: underline;
            }
          }
        }

        .delete__stage {
          cursor: pointer;
        }
      }
    }

    .play__button {
      @include flex-center;
      a {
        background-color: $themecolor;
        color: $white;
        padding: 10px 15px;

        @media (max-width:340px) {
          font-size: 12px;
        }
      }


    }
  }
}
