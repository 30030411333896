@use "../common/breakpoints" as *;
@use "../common/mixin" as *;
@use "../common/color" as *;

.LeaderBoard {
  padding-bottom: 50px;
  .morphisam {
    @include glassmorphism-effect;

    padding: 20px;

    @media #{$extra-small-device} {
      padding: 10px;
    }

    @media #{$max-wid-device} {
      margin-bottom: 20px;
    }

    &::before {
      position: absolute;
      left: -120px;
      content: "";
      top: -150px;
      width: 300px;
      height: 300px;
      background-color: $white;
      opacity: 0.2;
      border-color: $white;
      border-radius: 50%;
    }

    h5 {
      color: $white;

      @media #{$large-device} {
        margin: 5px auto 20px auto;
      }
    }

    .selectvalue {
      width: 100%;
    }

    .two_content_data {
      @include flex-center;
      justify-content: flex-end;

      .sorting_entries {
        margin-right: 10px;
        span {
          color: $white;
        }
      }
      .sorting_area {
        span {
          color: $white;
        }
      }
    }

    .ant-select {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          background-color: transparent;
          border-radius: 5px;
        }
      }
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      @include flex-center;
      border-radius: 50%;
      background-color: #ffffff61;
      box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
    }

    .ant-pagination-item {
      border-radius: 50%;
      background-color: #ffffffbd;
      box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
    }

    .ant-pagination {
      display: flex;
      justify-content: flex-end;

      @media #{$large-device} {
        justify-content: center;
        margin-top: 15px;
      }
    }

    .table_content {
      table {
        width: 100%;
        border-radius: 5px;
        box-shadow: inset 5px 5px 30px rgba(0, 0, 0, 0.2);
      }

      th {
        text-align: center;
        border-bottom: 1px solid #d9d9d959;
        color: $white;
        font-weight: 900;
        padding: 10px;
      }

      td {
        text-align: center;
        color: $white;
        padding: 10px;
      }

      .App {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .bhoXfZ {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .fvAeSn {
        padding: 10px;
        justify-content: center;
      }

      .hCBnvI {
        background-color: inherit;
      }

      .iAwKFK {
        background-color: inherit;
      }

      .bhoXfZ {
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(10px);
      }

      .fFBlx{
        padding-left: 15px;
      }
    }

    .table_bottom_footer {
      p {
        margin-bottom: 0;
        color: $white;

        @media #{$large-device} {
          text-align: center;
        }
      }
    }

    .leaderboard-profile {
      width: 80px;
      height: 80px;
     @include flex-center;

      img {
        border-radius: 5px;
        object-fit: contain;
        max-width: 100%;
      }
    }
  }
}

.rdt_TableBody,
.hLGqlg,
.iAwKFK {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
