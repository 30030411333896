@use "../../common/breakpoints" as *;
@use "../../common/mixin" as *;
@use "../../common/color" as *;

.contestPlay {
    display: flex;
    color: $white;

    .morphisam {
        @include glassmorphism-effect;
        padding: 30px 10px;

        &::before {
            position: absolute;
            left: -79px;
            content: "";
            top: -54px;
            width: 300px;
            height: 300px;
            background-color: $white;
            opacity: 0.2;
            border-color: $white;
            border-radius: 50%;
        }
    }

    .nav-link {
        color: #fff !important;

        &.active {
            color: black !important;
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(30px);
            border: 2px solid rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
        }
    }

    .nav-tabs {
        border-bottom: 1px solid #dddddd26;
        button {
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    .card {
        border-radius: 15px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(30px);
        border: 2px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
        margin-bottom: 15px;

        .card-image {
            img {
                height: 200px;
                // object-fit: contain;
                width: 100%;
                border-radius: 15px;
            }
        }
        .card-details {
            .card-title {
                padding-top: 10px;
                h3 {
                    font-size: 18px;
                    color: $white;
                }
                p {
                    color: $white;
                }
            }
            .card-footer {
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;

                p {
                    color: $white;
                    margin-bottom: 5px;
                }
                span {
                    color: $white;
                }

                .btn-play {
                    background-color: $themecolor;
                    color: $white;
                    margin-bottom: 0;
                }

                .upper-footer{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 15px;
                }

                .bottom-footer{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
}
