@use "../../common/breakpoints" as *;
@use "../../common/mixin" as *;
@use "../../common/color" as *;

.SelfLearning {
    display: flex;
    color: $white;

    .morphisam {
        @include glassmorphism-effect;
        padding: 30px 10px;

        &::before {
            position: absolute;
            left: -79px;
            content: "";
            top: -54px;
            width: 300px;
            height: 300px;
            background-color: $white;
            opacity: 0.2;
            border-color: $white;
            border-radius: 50%;
        }
    }
    .left-sec {
        .cat__Box {
            display: flex;
            flex-direction: row;
            position: relative;
            margin-bottom: 20px;

            .left-line {
                border: 1px solid rgb(179, 179, 179);
                margin: auto;
                display: block;
                width: 35px;
                align-items: center;
                justify-content: center;
                line-height: 2px;
            }

            .quizplay__title {
                margin: auto 10px;
                font-size: 1rem;
                @media #{$large-device} {
                    font-size: inherit;
                }
            }

            .right-line {
                border: 1px solid rgb(179, 179, 179);
                margin: auto;
                width: 35px;
                @include flex-center;
                line-height: 2px;
            }
        }

        .bottom__cat__box {
            display: flex;
            margin-bottom: 20px;

            .form-select {
                background-color: transparent;
                color: #fff;
                background-image: url("../../../images/drop-down-arrow.png");

                option {
                    color: #000;
                }
            }
        }
    }

    // subcatslider
    .subcat__slider__context {
        display: block;
        position: relative;

        .inner__icon {
            @include flex-center;
            margin: auto;
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            display: inline-block;
            background-color: grey;
        }

        .cat__Box {
            display: flex;
            flex-direction: row;
            // position: relative;
            position: absolute;
            top: 22px;
            @media (max-width:370px) {
                justify-content: center;
            }

            .left-line {
                border: 1px solid rgb(179, 179, 179);
                margin: auto;
                display: block;
                width: 15%;
                align-items: center;
                justify-content: center;
                line-height: 2px;
            }

            .quizplay__title {
                margin: auto 10px;
            }

            .right-line {

                @include flex-center;
                width: 15%;
                border: 1px solid #b3b3b3;
                /* margin: auto; */
                height: 2px;
                margin-top: 16px;
                margin-left: 10px;
                width: 20%;

                @media #{$large-device} {
                    display: none;
                }
            }

            .subcat__p {
                font-size: 1rem;
                display: block;
                margin: 5px auto;
                justify-content: center;
                text-align: center;
                align-items: center;

                @media #{$large-device} {
                    font-size: inherit;
                }

                @media (max-width:370px) {
                    font-size: 14px;
                }
            }
        }

        .quizplay-slider {
            padding: 20px 0px;
            position: relative;

            .subcatintro__sec {
                margin: 30px auto;
                .card {
                    height: 70px;
                    border-radius: 20px;
                    background-color: #5f5f5f;
                    cursor: pointer;

                    .card__name {
                        p:first-child {
                            font-size: 1rem;
                            font-weight: 800;
                        }
                        p {
                            font-size: 0.825rem;
                            font-weight: 500;
                            letter-spacing: 1px;
                        }
                    }
                    @media #{$large-device} {
                        height: 50px;
                    }
                }
            }

            .no_sub_data {
                position: absolute;
                top: 0%;
                left: 0;
                right: 0;
                @include flex-center;
            }

            .swiper-button-next {
                width: 30px;
                height: 30px;
                padding: 10px;
                top: 25px;
                right: 0px;
                color: $white;
                background-color: grey;
                // padding: 0px 23px;
                border-radius: 50%;
                opacity: 1;
                cursor: pointer;

                &:hover {
                    background-color: $themecolor !important;
                    overflow: hidden;

                    &::after {
                        animation: moveoutright 1s linear;
                    }

                    @keyframes moveoutright {
                        0% {
                            transform: translateX(0);
                        }
                        100% {
                            transform: translateX(100px);
                        }
                    }
                }

                &::after{
                    font-size: 10px!important;
                }

                @media #{$extra-small-device} {
                    display: none;
                }
            }

            .swiper-button-prev {
                width: 30px;
                height: 30px;
                padding: 10px;
                top: 25px;
                right: 45px!important;
                left: auto;
                color: $white;
                background-color: grey;
                // padding: 0px 23px;
                border-radius: 50%;
                opacity: 1;
                cursor: pointer;

                &:hover {
                    background-color: $themecolor !important;
                    overflow: hidden;

                    &::after {
                        animation: moveoutleft 1s linear;
                    }

                    @keyframes moveoutleft {
                        0% {
                            transform: translateX(0);
                        }
                        100% {
                            transform: translateX(-100px);
                        }
                    }
                }

                &::after{
                    font-size: 10px!important;
                }

                @media #{$extra-small-device} {
                    display: none;
                }
            }

            // .swiper-wrapper {
            //     margin: 0 60px;

            //     @media #{$large-device} {
            //         margin: auto;
            //     }
            // }
        }

        .sub_cat_title {
            @include flex-center;
        }

        .active-one {
            color: $themecolor;
            background-color: $mate-black !important;
            border: 2px solid $themecolor;
        }

        .unactive-one {
            color: $mate-black;
        }
    }

    // start button
    .start_btn {
        background-color: $themecolor;
        color: $white;
    }

    .selectform {
        font-size: 1rem;
    }

    .swiper-wrapper{
        padding-top: 30px;
    }

}

.selflearnig-play {
    .coinsdata {
        width: auto !important;
        height: auto !important;
        padding: 20px 10px;
    }
}

.activeEle {
    .spandiv {
        background-color: $themecolor !important;
        transition: 0.2s ease-in;
    }
}

.que_box {
    // background-color: $themecolor;
    color: $mate-black;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 0;
    border: 1px solid #212121;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.que_content {
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.que_pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


.self-modal {
    .ant-modal-body {
        height: auto!important;
        overflow-y: auto!important;
    }

    input[type='radio'] {
        -webkit-appearance:none;
        width:20px;
        height:20px;
        border-radius:50%;
        outline:none;
        box-shadow:0 0 5px 0px gray inset;
    }

    input[type='radio']:before {
        content:'';
        display:block;
        width:60%;
        height:60%;
        margin: 20% auto;
        border-radius:50%;
    }

    input[type='radio']:checked:before {
        background:$themecolor;
    }
}



.ant-modal-close-x{
        border-radius: 50px;
        width: 0;
        height: 0;
        line-height: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-shadow: 0 0 5px 0px grey inset;
}

.que_box{
    &.bg-green{
        background-color: $themecolor;
        color: $white;
    }
}

.que_box{
    &.bg-dark{
        background-color: transparent!important;
        color: #000;
    }
}

.bg-theme{
    background-color: $themecolor;
        color: $white;
}